import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { padStart } from 'lodash'
import { motion, useViewportScroll, transform } from 'framer-motion'

import { Layout, PageHero, PageSection, FeaturedEngagements } from '../components'

import { container, padding, colours, type, bgImage, pagePaddingTop, wrapper, bgIcon } from '../styles/global'
import { parseACF } from '../utils'
import { useScrollAnim } from '../utils/anim'
import { media } from '../styles/utils'

const resources = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

	const renderFooterContact = () => {
		return (
			<FooterContact>
				{data.contact_heading && (
					<Heading
						dangerouslySetInnerHTML={{__html: data.contact_heading}}
					/>
				)}

				{data.contact_text && (
					<Text
						dangerouslySetInnerHTML={{__html: data.contact_text}}
					/>
				)}
			</FooterContact>
		)
	}

	const renderIntro = (title, introText, bodyText) => {
        if (!data.intro_text) return

		const section = {
			items: [
				{
                    intro_text: data.intro_text,
				}
			]
		}

        return (
			<Intro>
				<PageSection
					className={'intro'}
					{...section}
				/>
			</Intro>
        )
	}

	const renderTextLinks = (item) => {
		if (!item.links) return;

		const links = item.links.map((link, i) => {
			return (
				<ItemLink 
					key={i}
					as={'a'}
					target={'_blank'}
					href={link.url}
				>
					<LinkIcon
						type={item.link_icon}
					/>

					<Heading>
						{link.title}
					</Heading>

					<LinkArrow/>	
				</ItemLink>
			)
		})

		return (
			<TextLinks>
				{links}
			</TextLinks>
		)
	}

	const renderImageLinks = (item) => {
		if (!item.links) return;

		const links = item.links.map((link, i) => {
			return (
				<ItemLink 
					key={i}
					as={'a'}
					target={'_blank'}
					href={link.url}
				>

					{link.image && (
						<BGImage
							image={link.image?.sizes?.medium2}
						/>	
					)}

					<Info>
						<LinkIcon
							type={item.link_icon}
						/>

						<Heading>
							{link.title}
						</Heading>

						<LinkArrow/>	
					</Info>	
				</ItemLink>
			)
		})

		return (
			<ImageLinks>
				{links}
			</ImageLinks>
		)
	}
	
	const renderLinks = (item) => {
		if (!item) return;

		if (item.links_type == 'text') return renderTextLinks(item)
		if (item.links_type == 'image') return renderImageLinks(item)
	}

	const renderSections = () => {
		if (!data.sections) return;

		const items = data.sections.map((item, i) => {
			if (!item.display) return;
			
			item.items = [
				{
					...item,
					heading: item.title,
					text: item.intro_text, 
					type_size: 'medium',
					additionalContent: renderLinks(item)
				}
			]

			return (
				<PageSection 
					key={i}
					className={`page_section_${i + 1}`}
					{...item}
					title={padStart(i + 1, 2, '0')}
				/>
			)
		})

		return items
	}

	return (
		<Layout
			meta={data.seo}
			footerContact={renderFooterContact()}
        >
			<Wrapper>
                <PageHero
					data={data}
					slim
                />

                <Container>
					{renderIntro()}
					{renderSections()}
                </Container>
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``


// Layout

const Wrapper = styled.div`
	${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
	display: flex;
	flex-direction: column;
	padding-top: 51px;	

	${media.phone`
		padding-top: 85px;
	`}

	/* Block overrides */

	.block_text_image {
		.title {
			margin-bottom: 42px;
		}

		.content {
			max-width: 610px;
		}

		img {
			max-width: 100%;

			${media.phone`
				max-height: 230px;
			`}
		}

		${media.phone`
			.content {
				order: 1;
			}

			.media { 
				order: 2;
				margin-top: 28px;
				margin-bottom: 0;
			}

			.title {
				margin-bottom: 20px;
			}
		`}
	}

	.page_section_1 {
		img {
			max-height: 248px;
		}
	}
`

// Intro

const Intro = styled.div`
	.intro {
		.page-section-content {
			border-bottom: 1px solid white;
			margin-bottom: 113px;

			.page-section-item {
				margin-bottom: 104px;

				${media.phone`
					margin-bottom: 40px !important;
				`}

				.content {
					max-width: 743px;
				}
			}

			${media.phone`
				margin-bottom: 40px;
			`}
		}
	}
`

// Links (Shared)

const ItemLink = styled.div``

const LinkIcon = styled.div`
	${bgIcon}
	margin-right: 19px;

	${props => {
		if (props.type == 'file') return css`
			background-image: url(${require('../assets/icons/file.svg')});
			min-width: 15px;
			min-height: 20px;
		`

		if (props.type == 'globe') return css`
			background-image: url(${require('../assets/icons/globe.svg')});
			min-width: 19px;
			min-height: 19px;
		`
	}}
`

const LinkArrow = styled.div`
	${bgIcon}
	background-image: url(${require('../assets/icons/download-arrow.svg')});
	min-width: 16px;
	min-height: 16px;
`


const linkLabel = css`
	display: flex;
	align-items: center;
	transition: opacity 0.25s ease;

	padding: 14px 0 12px;
	border-bottom: 1px solid white;

	${LinkArrow} {
		margin-left: auto;
	}

	${Heading} {
		${type.heading4};
		margin-right: auto;
	}

	&:hover {
		opacity: 1 !important;
	}

	${media.phone`
		${Heading} {
			font-size: 24px;
		}

		${LinkArrow} {
			margin-left: 32px;
			align-self: flex-start;
		}

		${LinkIcon} {
			align-self: flex-start;
			margin-right: 10px;
		} 
	`}
`

// Text Links

const TextLinks = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 55px;

	${media.phone`
		margin-top: 10px;
	`}

	${ItemLink} {
		${linkLabel}
	}

	&:hover {
		${ItemLink} {
			opacity: 0.4
		}
	}
`

// Image Links	

const ImageLinks = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 30px);
	margin-top: 60px;
	
	${media.phone`
		margin-top: 24px;
		width: 100%;
	`}

	${ItemLink} {
		flex: 1 0 calc(33.3% - 30px);
		margin-right: 30px;
		display: flex;
		flex-direction: column;

		${BGImage} {
			padding-bottom: 66%;
		}

		${Info} {
			${linkLabel}
			margin-top: 5px;
			flex: 1;
		}

		${media.phone`
			flex: 1 0 100%;
			margin-right: 0;

			&:not(:first-child) {
				${BGImage} {
					display: none;
				}

				${Info} {
					margin-top: 0;
				}
			}
		`}
	}
	
`

// Footer Content

const FooterContact = styled.div`
	> * {
		${type.heading4}
		line-height: 1.25;

		${media.phone`
			line-height: 0.94em;
		`}
	}

	${Heading} {
		margin-bottom: 40px;

		${media.phone`
			margin-bottom: 16px;
		`}
	}

	a {
		box-shadow: none;
	}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "resources" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default resources